import { Box, Button, Container, Typography, Stack, CssBaseline } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { navigateToUrl } from 'single-spa';

function Congratulations() {

  const [author, setAuthor] = useState('');

  const handleGoHome = () => {
    navigateToUrl('/');
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = parsedUser.UserAttributes?.find(attr => attr.Name === 'nickname')?.Value || 'N/A';
      setAuthor(nickname);
      console.log(nickname)
    }
  }, []);

  return (
    <Container component="main" maxWidth="xl">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 10,
        marginBottom: 9,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
        <Stack spacing={2} sx={{ width: '100%', textAlign: 'left' }}>
          <Typography variant="h4" fontFamily={'serif'}  sx={{ pl: 20 }}>
            Dear {author},
          </Typography>
          <Typography variant="h2" fontFamily={'serif'} textAlign="center">
          Congratulations on your listing!
          </Typography>
          <Typography variant="h6" fontFamily={'serif'} textAlign="center">
          Your cafe has been successfully listed. Our team will review the details and get in touch if any additional information is needed.<br />
          We appreciate your contribution and look forward to featuring your cafe!          </Typography>
          <Typography variant="h5" fontFamily={'serif'}  sx={{ pl: 120 }}>
          Best regards Kandy Mag Team
          </Typography>
        </Stack>

        <Button
          variant="contained"
          onClick={handleGoHome}
          sx={{ mt: 3, backgroundColor: "black", ":hover": { backgroundColor: "black" } }}
        >
          Home
        </Button>
      </Box>
    </Container>
  );
}

export default Congratulations