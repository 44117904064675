import ApiInstance from "../configs/apiInstance";

async function UpdateCafeDetails(cafeId, dataObject) {
    try {
        const response = await ApiInstance.cafeService.put(`${cafeId}`,
            dataObject
        );
        return response;
    } catch (error) {
        console.log("Error occurred when user update", error);
        throw error;
    }
}

export default UpdateCafeDetails;