import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Screen from './Screen';
import ListingSteps from './pages/ListingSteps';
import CafeProfile from './pages/CafeProfile';
import UpdateCafe from './pages/UpdateCafe';
import UpdateTime from './pages/UpdateTime';



function App() {
  return (
    <Router>
        <Routes>
            <Route path='/contributor/' element={<Screen/>} />
            <Route path='/contributor/listCafe' element={<ListingSteps/>} />
            <Route path='/contributor/viewcafedetails' element={<CafeProfile/>} />
            <Route path='/contributor/updatecafe' element={<UpdateCafe/>} />
            <Route path='/contributor/updatetime' element={<UpdateTime/>} />
        </Routes>
    </Router>
  )
}

export default App