import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import GetUserDetails from '../services/GetUserDetails';
import UpdateCafeTime from '../services/UpdateCafeTime';
import { navigateToUrl } from 'single-spa';
import dayjs from 'dayjs';

function UpdateTime() {
  const [errorMessage, setErrorMessage] = useState('');
  const [owner, setOwner] = useState('');
  const [open, setOpen] = useState(false);
  const [nickname, setNickname] = useState('');
  const [cafeId, setCafeId] = useState('');
  
  const [openingHours, setOpeningHours] = useState({
    openingMondayHours: null,
    openingTuesdayHours: null,
    openingWednesdayHours: null,
    openingThursdayHours: null,
    openingFridayHours: null,
    openingSaturdayHours: null,
    openingSundayHours: null,
  });

  const handleTimeChange = (day, fromTime, toTime) => {
    const formattedFrom = fromTime ? dayjs(fromTime).format('HH:mm') : '00:00';
    const formattedTo = toTime ? dayjs(toTime).format('HH:mm') : '00:00';

    setOpeningHours((prevHours) => ({
      ...prevHours,
      [day]: `${formattedFrom} - ${formattedTo}`,
    }));
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = getUserAttribute(parsedUser, 'nickname');
      fetchUserDetails(nickname);
      setNickname(nickname);
    }
  }, []);

  const fetchUserDetails = async (nickname) => {
    try {
      const userDetails = await GetUserDetails(nickname);
      sessionStorage.setItem('cafeId', userDetails.cafe);
    } catch (error) {
      console.error("Error occurred when getting user data", error);
    }
  };

  const getUserAttribute = (user, name) => {
    const attribute = user.UserAttributes.find(attr => attr.Name === name);
    return attribute ? attribute.Value : 'N/A';
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    
    const { 
      openingMondayHours, 
      openingTuesdayHours, 
      openingWednesdayHours, 
      openingThursdayHours, 
      openingFridayHours, 
      openingSaturdayHours, 
      openingSundayHours 
    } = openingHours;

    try {
      await UpdateCafeTime(cafeId, openingMondayHours, openingTuesdayHours, openingWednesdayHours, openingThursdayHours, openingFridayHours, openingSaturdayHours, openingSundayHours);
      navigateToUrl('/contributor');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    const id = sessionStorage.getItem('cafeId');
    setCafeId(id);
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const sub = parsedUser.UserAttributes?.find(attr => attr.Name === 'sub')?.Value || 'N/A';
      setOwner(sub);
    }
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 9,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            m: 1,
            width: 56,
            height: 56,
            mb: 2,
            background: 'linear-gradient(90deg, rgba(218, 7, 28, 1), rgba(241, 223, 30, 1))'
          }}
        >
          <StorefrontIcon />
        </Avatar>

        <Typography component="h1" variant="h3">
          Update Time
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Opening Hours</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                  <Box key={day} sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>{day}</Typography>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                       ampm={false}
                        label="From"
                        value={openingHours[`opening${day}Hours`] ? dayjs(openingHours[`opening${day}Hours`].split(' - ')[0], 'HH:mm') : null}
                        onChange={(time) => handleTimeChange(`opening${day}Hours`, time, openingHours[`opening${day}Hours`] ? dayjs(openingHours[`opening${day}Hours`].split(' - ')[1], 'HH:mm') : null)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <TimePicker
                      ampm={false}
                        label="To"
                        value={openingHours[`opening${day}Hours`] ? dayjs(openingHours[`opening${day}Hours`].split(' - ')[1], 'HH:mm') : null}
                        onChange={(time) => handleTimeChange(`opening${day}Hours`, openingHours[`opening${day}Hours`] ? dayjs(openingHours[`opening${day}Hours`].split(' - ')[0], 'HH:mm') : null, time)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </DemoContainer>
                  </Box>
                ))}
                
                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    background: 'linear-gradient(90deg, rgba(218, 7, 28, 0.9), rgba(241, 223, 30, 0.9))',
                    "&:hover": {
                      background: 'linear-gradient(90deg, rgba(218, 7, 28, 0.9), rgba(241, 223, 30, 0.9))',
                    },
                  }}
                >
                  Update Times
                </Button>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Collapse in={open}>
            <Alert
              severity={errorMessage.includes('Error') ? 'success' : 'error'}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
        </Box>
      </Box>
    </Container>
  );
}

export default UpdateTime;
