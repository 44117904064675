import React, { useEffect, useState } from 'react';
import { Container, Box, Grid, Divider } from '@mui/material';
import CoverImage from '../components/profile/CoverImage';
import Informations from '../components/profile/Informations';
import Time from '../components/profile/Time';
import CafeImagesBanner from '../components/profile/CafeImagesBanner';
import getCafeDetails from '../services/GetCafeDetails';

function CafeProfile() {

  const [cafe, setCafe] = useState([]);

  useEffect(() => {
    const fetchCafeDetails = async () => {
      try {
        const cafeId = sessionStorage.getItem('cafeId');
        if (cafeId) {
          const cafeDetails = await getCafeDetails(cafeId);
          setCafe(cafeDetails);
          console.log(cafeDetails)
        }
      } catch (error) {
        console.error("Error fetching cafe details:", error);
      }
    };

    fetchCafeDetails();
  }, []);

  return (
    <>
      <CoverImage cafe={cafe}/>
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Informations cafe={cafe}/>
            </Grid>
            <Grid item xs={6}>
              <CafeImagesBanner cafe={cafe}/>
            </Grid>
            <Grid item xs={3}>
              <Time cafe={cafe}/>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default CafeProfile;
