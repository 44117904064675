import React from 'react';
import { Button, Box, Typography, Container, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';

function PageButton({navigate, image, width, height }) {
  const navigateTo = useNavigate();

  return (
    <Box sx={{ width: {width}, height: {height} }}>
      <Button
        sx={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#fff',
          borderRadius: '45px',
          marginBottom: 1,
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.02)",
          }
        }}
        onClick={() => navigateTo(`/contributor/${navigate}`)}
      >
      </Button>
    </Box>
  );
}

export default PageButton;
