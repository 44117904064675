import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar, Button, Box, Container, Typography, Grid, IconButton,
  Collapse, Alert, CssBaseline, Divider, Stack, useMediaQuery
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import UploadCafeImage from '../services/UploadCafeImage';
import GetCafeImage from '../services/GetCafeImage';
import image from '../images/image.jpg'; // Fallback image
import SaveImageKeys from '../services/SaveImageKeys';
import GetUserDetails from '../services/GetUserDetails';
import UpdateCafeProfileImage from '../services/UpdateCafeProfileImage';
import UpdateCafeCoverImage from '../services/UpdateCafeCoverImage';
import { useTheme } from '@mui/material/styles';

const UploadImages = ({ onStepChange }) => {
  const [files, setFiles] = useState(Array(6).fill(null));
  const [fileErrors, setFileErrors] = useState(Array(6).fill(''));
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [uploadedImageUrls, setUploadedImageUrls] = useState(Array(6).fill(''));
  const [s3Keys, setS3Keys] = useState(Array(6).fill(''));
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [coverImageUrl, setCoverImageUrl] = useState('');
  const [cafeId, setCafeId] = useState('');

  const fileInputRefs = useRef([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleUploadProfileImage = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('cafeImage', selectedFile);

      try {
        const { cafeImageKey } = await UploadCafeImage(formData);
        const profileImage = cafeImageKey;
        await UpdateCafeProfileImage(cafeId,profileImage);
        const profileImgUrl = await GetCafeImage(cafeImageKey);
        setProfileImageUrl(profileImgUrl);
        console.log('Profile Image URL:', profileImgUrl);
      } catch (error) {
        showAlert(error);
      }
    }
  };

  useEffect( async () => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        const nickname = getUserAttribute(parsedUser, 'nickname');
        const userDetails =  await GetUserDetails(nickname);
        setCafeId(userDetails.cafe);
        sessionStorage.setItem('cafeId',userDetails.cafe);
    }

}, []);

const getUserAttribute = (user, name) => {
  const attribute = user.UserAttributes.find(attr => attr.Name === name);
  return attribute ? attribute.Value : 'N/A';
};

    

  const handleUploadCoverImage = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('cafeImage', selectedFile);

      try {
        const { cafeImageKey } = await UploadCafeImage(formData);
        const coverImage = cafeImageKey;
        await UpdateCafeCoverImage(cafeId, coverImage);
        const coverImgUrl = await GetCafeImage(cafeImageKey);
        setCoverImageUrl(coverImgUrl);
        console.log('Cover Image URL:', coverImgUrl);
      } catch (error) {
        showAlert(error);
      }
    }
  };

  const handleFileChange = async (index, event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const newFiles = [...files];
      newFiles[index] = selectedFile;
      setFiles(newFiles);

      const formData = new FormData();
      formData.append('cafeImage', selectedFile);

      try {
        const { cafeImageKey } = await UploadCafeImage(formData);
        const imageUrl = await GetCafeImage(cafeImageKey);

        const newImageUrls = [...uploadedImageUrls];
        newImageUrls[index] = imageUrl;
        setUploadedImageUrls(newImageUrls);

        const newS3Keys = [...s3Keys];
        console.log(newS3Keys)
        newS3Keys[index] = cafeImageKey;
        setS3Keys(newS3Keys);
        

        console.log('Image URL:', imageUrl);
        console.log('S3 Key:', cafeImageKey);
      } catch (error) {
        showAlert(error);
        const newErrors = [...fileErrors];
        newErrors[index] = error.message;
        setFileErrors(newErrors);
      }
    } else {
      const newErrors = [...fileErrors];
      newErrors[index] = 'No file selected';
      setFileErrors(newErrors);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (s3Keys.every(key => key)) {
      try {
        await SaveImageKeys(cafeId, s3Keys); 
        onStepChange(2);
        console.log('S3 keys saved successfully');
      } catch (error) {
        showAlert(error);
      }
    } else {
      showAlert(new Error('Please upload all images before submitting.'));
    }
  };
  
  const handleProfileImageClick = () => {
    fileInputRefs.current['profile'].click();
  };

  const handleCoverImageClick = () => {
    fileInputRefs.current['cover'].click();
  };

  const handleButtonClick = (index) => {
    fileInputRefs.current[index].click();
  };

  const showAlert = (error) => {
    setOpen(true);
    setErrorMessage(error.message);
  };

  
  return (
    <Container maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 9,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: isMobile ? 'center' : 'left',
        }}
      >
        <Avatar
          sx={{
            m: 1,
            width: 56,
            height: 56,
            mb: 2,
            background: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
          }}
        >
          <ImageIcon />
        </Avatar>

        <Typography component="h1" variant="h3" fontFamily='serif'>
          Upload Images
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ alignItems: 'center', mt: 5, width: '100%' }}>
          <Stack spacing={5}>
            {!isMobile && (
              <Divider textAlign="left" sx={{ mb: 3, mt: 5 }}>
                <Typography variant="h6" fontWeight={800}>
                  Add Profile Image and Cover Image
                </Typography>
              </Divider>
            )}
            <Grid container justifyContent={'center'} spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
                    width: '100%',
                    mb: 3
                  }}
                >
                  <Button
                    onClick={handleProfileImageClick}
                    sx={{
                      height: '100%',
                      width: '100%',
                      backgroundImage: `url(${profileImageUrl || image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      border: '1px solid #ccc',
                    }}
                  />
                  <input
                    type="file"
                    onChange={handleUploadProfileImage}
                    ref={(ref) => (fileInputRefs.current['profile'] = ref)}
                    style={{ display: 'none' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={handleCoverImageClick}
                    sx={{
                      height: '100%',
                      width: '100%',
                      backgroundImage: `url(${coverImageUrl || image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      border: '1px solid #ccc',
                    }}
                  />
                  <input
                    type="file"
                    onChange={handleUploadCoverImage}
                    ref={(ref) => (fileInputRefs.current['cover'] = ref)}
                    style={{ display: 'none' }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 3 }}>
              <Typography variant="h6" fontWeight={800}>
                Add Cafe Images
              </Typography>
            </Divider>
            <Grid container spacing={2} justifyContent={isMobile ? 'center' : 'flex-start'}>
              {Array.from({ length: 6 }, (_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={index}
                  sx={{ textAlign: isMobile ? 'center' : 'left' }}
                >
                  <Box
                    sx={{
                      height: 200,
                      width: '100%',
                      cursor: 'pointer',
                      backgroundImage: `url(${uploadedImageUrls[index] || image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      border: '1px solid #ccc',
                      mb: 3
                    }}
                    onClick={() => handleButtonClick(index)}
                  />
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(index, e)}
                    ref={(ref) => (fileInputRefs.current[index] = ref)}
                    style={{ display: 'none' }}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
          <Button type="submit" fullWidth variant="contained" color="primary"
            sx={{
              mt: 3,
              mb: 2,
              fontWeight: 550,
              letterSpacing: 2,
              background: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
              "&:hover": {
                background: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
              }
            }}>
            Submit
          </Button>
        </Box>

        {/* Display error message */}
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mt: 2 }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
      </Box>
    </Container>
  );
};

export default UploadImages;
