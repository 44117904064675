import ApiInstance from "../configs/apiInstance";

async function UpdateCafeCoverImage(id,coverImage) {
    try {
        const response = await ApiInstance.cafeService.put(`${id}`,{
            coverImage,
        });
        const data = response.data;
        console.log(data)
        return data;
    } catch (error) {
        console.log("Error occurred when user update", error);
        throw error;
    }
}

export default UpdateCafeCoverImage;