import React from 'react';
import { Box } from "@mui/material";
import contributorPanelImage from '../images/ContributorPanel.png';

function Banner() {
    return (
        <Box
            component="img"
            sx={{
                width: '100%',
                height: {
                    xs: '150px', 
                    sm: '300px', 
                    md: '400px',
                },
                objectFit: 'cover',
                display: 'block',
                margin: 0,
                padding: 0,
            }}
            src={contributorPanelImage}
            alt="Contributor Banner"
        />
    );
}

export default Banner;
