import React from 'react'
import Home from './pages/Home'
import Banner from './components/Banner'

function Screen() {
    return (
        <>
            <Banner />
            <Home />
        </>
    )
}

export default Screen