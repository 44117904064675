import ApiInstance from "../configs/apiInstance";

async function UpdateCafeTime(cafeId, openingMondayHours, openingTuesdayHours, openingWednesdayHours, openingThursdayHours, openingFridayHours,openingSaturdayHours, openingSundayHours) {
    try {
        const response = await ApiInstance.cafeService.put(`${cafeId}`,
            {
                openingMondayHours, 
                openingTuesdayHours, 
                openingWednesdayHours, 
                openingThursdayHours, 
                openingFridayHours,
                openingSaturdayHours, 
                openingSundayHours
            }

        );
        return response;
    } catch (error) {
        console.log("Error occurred when user update", error);
        throw error;
    }
}

export default UpdateCafeTime;