import React, { useEffect, useState } from 'react'
import { Container, Grid, Box, Divider, Typography, CssBaseline } from '@mui/material';
import PageButton from '../components/PageButton';
import buttonImageview from '../images/button_view.png';
import buttonUpdateDetails from '../images/button_updateDetails.png';
import buttonUpdateTime from '../images/button_updateTime.png';

function Home() {

  const [show, setShow] = useState(true);

  useEffect(() => {
    const cafeId = sessionStorage.getItem('cafeId');
    if (cafeId == null) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: 5, px: 2 }}>
      <CssBaseline />
      <Grid container spacing={3} sx={{ mb: 10, justifyContent: "center" }}>
        {show && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box display="flex" justifyContent="center" height="100%">
              <PageButton navigate={"listCafe"} image={buttonImageview}  width={"500px"} height={"250px"}/>
            </Box>
          </Grid>
        )}
        {!show && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box display="flex" justifyContent="center" height="100%">
              <PageButton navigate={"viewcafedetails"} image={buttonImageview} />
            </Box>
          </Grid>
        )}
        {!show && (

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box display="flex" justifyContent="center" height="100%">
              <PageButton navigate={"updatecafe"} image={buttonUpdateDetails} />
            </Box>
          </Grid>
        )}
        {!show && (

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box display="flex" justifyContent="center" height="100%">
              <PageButton navigate={"updatetime"} image={buttonUpdateTime} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Home;
