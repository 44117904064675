import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import React from 'react'

function Time({cafe}) {
    return (
        <>
        <Box
          height={305}
          display="flex"
          flexDirection="column"
          p={2}
          mb={1}
          sx={{
            background:"linear-gradient(90deg, rgba(218, 7, 28, 0.5), rgba(241, 223, 30, 0.5))"
          }}
          >
            <Stack spacing={1}>
            <Typography fontWeight={800}  variant='h5'>Opening Hours</Typography> 
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant='body1'>Monday :</Typography>
                <Typography variant='body1'>{cafe.openingMondayHours}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant='body1'>Tuesday :</Typography>
                <Typography variant='body1'>{cafe.openingTuesdayHours}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant='body1'>Wednesday :</Typography>
                <Typography variant='body1'>{cafe.openingWednesdayHours}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant='body1'>Thursday :</Typography>
                <Typography variant='body1'>{cafe.openingThursdayHours}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant='body1'>Friday :</Typography>
                <Typography variant='body1'>{cafe.openingFridayHours}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant='body1'>Saturday :</Typography>
                <Typography variant='body1'>{cafe.openingSaturdayHours}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant='body1'>Sunday :</Typography>
                <Typography variant='body1'>{cafe.openingSundayHours}</Typography>
            </Stack>
            </Stack>
        </Box>
        <Button
          sx={{
            width: '100%',
            height: 375,
            backgroundImage: 'url("https://content.jdmagicbox.com/comp/gurgaon/v5/011pxx11.xx11.221017170720.v1v5/catalogue/barista-airia-mall--sohna-gurgaon-coffee-shops-fywml81ehe.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: '#fff',
            marginBottom: 1,
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.009)",
            }
          }}
        />
        </>
    )
}

export default Time