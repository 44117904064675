import apiInstance from "../configs/apiInstance"

const UploadCafeImage = async (file) => {
    try {
        const response = await apiInstance.userAwsImageService.post('/upload-cafe/images', file);
        const imageId = response.data;
        return imageId;
    } catch (error) {
        console.error('Image upload failed:', error);
        throw error;
    }
};

export default UploadCafeImage