import apiInstance from "../configs/apiInstance"

const SaveImageKeys = async (id,s3Keys) => {
    try {
        const imageKeys = s3Keys;
        const response = await apiInstance.cafeService.put(`/${id}/images/add`, { imageKeys });
        return response;
    } catch (error) {
        console.error('Image upload failed:', error);
        throw error;
    }
};

export default SaveImageKeys