import React, { useState } from 'react';
import HorizontalLinearStepper from '../components/HorizontalLinearStepper';
import { Container, Grid, Typography } from '@mui/material';
import UploadImages from './UploadImages';
import Congratulations from './Congratulations';
import ListCafe from './ListCafe';

function ListingSteps() {
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <UploadImages onStepChange={handleStepChange} />;
      case 1:
        return <UploadImages onStepChange={handleStepChange} />;
      case 2:
        return <Congratulations />;
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="xl">
       <Grid container spacing={2}>
         <Grid item xs={12} md={12} lg={12}>
          <HorizontalLinearStepper activeStep={currentStep} />
        </Grid>

         <Grid item xs={12}>
          {renderComponent()}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ListingSteps;
