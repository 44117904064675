import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Container, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepIcon from '@mui/material/StepIcon';

const steps = ['Add Details', 'Upload Images', 'Congratulations'];

const CustomStepIcon = styled(StepIcon)(({ theme }) => ({
  background: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
  borderRadius: '50%',
  color: 'transparent',
  '&.Mui-active': {
    background: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
    color: 'black',
  },
  '& .MuiStepIcon-text': {
    fill: 'white',
  },
}));

export default function HorizontalLinearStepper({ activeStep }) {
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box sx={{ width: '100%', mt: 10 }}>
        <Stepper activeStep={activeStep} connector={<StepConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={CustomStepIcon}
                sx={{
                  '& .MuiStepLabel-label': {
                    fontSize: {
                      xs: '0.7rem',
                      md: '1rem',
                      lg: '1.2rem'
                    },
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

      </Box>
    </Container>
  );
}
