import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert, Collapse, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontIcon from '@mui/icons-material/Storefront';
import UpdateCafeDetails from '../services/UpdateCafeDetails';
import GetUserDetails from '../services/GetUserDetails';
import '../components/styles/styles.css'
import getCafeDetails from '../services/GetCafeDetails';
import { navigateToUrl } from 'single-spa';


function UpdateCafe() {


  const [cafeId, setCafeId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [nickname, setNickname] = useState('');
  
  
  const [owner, setOwner] = useState('');
  const [name, setName] = useState('');
  const [address,setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber,setContactNumber] = useState('')
  const [description, setDescription] = useState('');
  const [googleMapURL, setGoogleMapURL] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [instagramURL, setInstagramURL] = useState('');
  const [youtubeURL, setYoutbeURL] = useState('');

  const charLimit = 1500;

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = getUserAttribute(parsedUser, 'nickname');
      fetchUserDetails(nickname);
      setNickname(nickname)
    }
  }, []);


  const fetchUserDetails = async (nickname) => {
    try {
      const userDetails = await GetUserDetails(nickname);
      sessionStorage.setItem('cafeId', userDetails.cafe);
    } catch (error) {
      console.error("Error occurred when getting user data", error);
    }
  };
  const getUserAttribute = (user, name) => {
    const attribute = user.UserAttributes.find(attr => attr.Name === name);
    return attribute ? attribute.Value : 'N/A';
  };

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    if (newDescription.length <= charLimit) {
      setDescription(newDescription);
    } else {
      setOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const dataObject = {
      name: formData.get('name'),
      address: formData.get('address'),
      email: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      description: formData.get('description'),
      websiteURL: formData.get('websiteUrl'),
      googleMapURL: formData.get('googleMapUrl'),
      facebookURL: formData.get('facebookUrl'),
      instagramURL: formData.get('instagramUrl'),
      youtubeURL: formData.get('youtubeUrl'),
    };

    const requiredFields = ['name', 'address', 'email', 'contactNumber', 'description'];
    for (const field of requiredFields) {
      if (!dataObject[field]) {
        setErrorMessage(`Please fill in the ${field}`);
        setOpen(true);
        return;
      }
    }

    try {
      await UpdateCafeDetails(cafeId, dataObject);
      navigateToUrl('/contributor');
      setErrorMessage('Cafe created successfully!');
    } catch (error) {
      setErrorMessage('Error creating cafe. Please try again.');
    }
  };


  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    const id = sessionStorage.getItem('cafeId')
    fetchCafeDetails(id);
    setCafeId(id);
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const sub = parsedUser.UserAttributes?.find(attr => attr.Name === 'sub')?.Value || 'N/A';
      setOwner(sub);
    }
  }, []);

  const fetchCafeDetails = async (cafeId) => {
    try {
      const cafeDetails = await getCafeDetails(cafeId);
      setName(cafeDetails.name)
      setAddress(cafeDetails.address)
      setEmail(cafeDetails.email)
      setContactNumber(cafeDetails.contactNumber)
      setDescription(cafeDetails.description)
      setGoogleMapURL(cafeDetails.googleMapURL)
      setWebsiteURL(cafeDetails.websiteURL)
      setFacebookURL(cafeDetails.facebookURL)
      setInstagramURL(cafeDetails.instagramURL)
      setYoutbeURL(cafeDetails.youtubeURL)

    } catch (error) {
      console.error("Error occurred when getting data", error);
    }
  }

  

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 9,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            m: 1,
            width: 56,
            height: 56,
            mb: 2,
            background: 'linear-gradient(90deg, rgba(218, 7, 28, 1), rgba(241, 223, 30, 1))'
          }}
        >
          <StorefrontIcon />
        </Avatar>

        <Typography component="h1" variant="h3">
          Update Cafe Details
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Name</Typography>
              <TextField
                className="gradient-textfield"
                name="name"
                value={name}
                fullWidth
                id="name"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Address</Typography>
              <TextField
                className="gradient-textfield"
                name="address"
                required
                fullWidth
                multiline
                rows={3}
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Email</Typography>
              <TextField
                className="gradient-textfield"
                required
                fullWidth
                type='email'
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Contact Number</Typography>
              <TextField
                className="gradient-textfield"
                required
                fullWidth
                id="contactNumber"
                name="contactNumber"
                type="number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Description</Typography>
                <TextField
                  className="gradient-textfield"
                  fullWidth
                  variant='outlined'
                  multiline
                  rows={20}
                  id='description'
                  name='description'
                  onChange={handleDescriptionChange}
                  inputProps={{ maxLength: charLimit }}
                  required
                  value={description}
                />
                <Typography variant='caption' display='block' mt={1} gutterBottom>
                  {description.length}/{charLimit} characters
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Website URL</Typography>
              <TextField
                className="gradient-textfield"
                fullWidth
                id="websiteUrl"
                name="websiteUrl"
                value={websiteURL}
                onChange={(e) => setWebsiteURL(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Google Map URL</Typography>
              <TextField
                className="gradient-textfield"
                fullWidth
                id="googleMapUrl"
                name="googleMapUrl"
                value={googleMapURL}
                onChange={(e) => setGoogleMapURL(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Facebook URL</Typography>
              <TextField
                className="gradient-textfield"
                fullWidth
                id="facebookUrl"
                name="facebookUrl"
                value={facebookURL}
                onChange={(e) => setFacebookURL(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>Instagram URL</Typography>
              <TextField
                className="gradient-textfield"
                fullWidth
                id="instagramUrl"
                name="instagramUrl"
                value={instagramURL}
                onChange={(e) => setInstagramURL(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h6' sx={{ fontWeight: 700, mb: 1 }}>YouTube URL</Typography>
              <TextField
                className="gradient-textfield"
                fullWidth
                id="youtubeUrl"
                name="youtubeUrl"
                value={youtubeURL}
                onChange={(e) => setYoutbeURL(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  background: 'linear-gradient(90deg, rgba(218, 7, 28, 0.9), rgba(241, 223, 30, 0.9))',
                  "&:hover": {
                  background: 'linear-gradient(90deg, rgba(218, 7, 28, 0.9), rgba(241, 223, 30, 0.9))',
                  },
                }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <Collapse in={open}>
            <Alert
              severity={errorMessage.includes('Error') ? 'success' : 'error'}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
        </Box>
      </Box>
    </Container>
  );
}

export default UpdateCafe;
