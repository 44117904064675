import React, { useEffect, useState } from 'react';
import { Avatar, Box, Typography } from "@mui/material";
import defaultCoverImage from '../../images/coverImage.png';
import GetCafeImage from '../../services/GetCafeImage';

function CoverImage({ cafe }) {
    const [profileImage, setProfileImage] = useState();
    const [coverImage, setCoverImage] = useState(defaultCoverImage);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                if (cafe?.profileImage) {
                    const profileImageKey = await GetCafeImage(cafe.profileImage);
                    setProfileImage(profileImageKey);
                }

                if (cafe?.coverImage) {
                    const coverImageKey = await GetCafeImage(cafe.coverImage);
                    console.log(coverImageKey)
                    setCoverImage(coverImageKey);
                }
            } catch (error) {
                console.error("Failed to fetch image URLs:", error);
            }
        };
        fetchImages();
    }, [cafe]);

    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                component="img"
                sx={{
                    top:45,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                src={coverImage}
                alt="Banner Image"
            />

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
                    zIndex: 1,
                }}
            />

            <Avatar
                sx={{
                    width: 250,
                    height: 250,
                    position: 'absolute',
                    bottom: 50,
                    left: 70,
                    border: '2px solid white',
                    zIndex: 2,
                    '@media (max-width: 600px)': {
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bottom: 20,
                    }
                }}
                src={profileImage}
                alt="Avatar"
            />

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 50,
                    left: 350,
                    zIndex: 2,
                    '@media (max-width: 600px)': {
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bottom: 20,
                    }
                }}
            >
                <Typography variant="h1" fontWeight={800} fontFamily={'serif'} color="white">
                    {cafe.name}
                </Typography>
            </Box>
        </Box>
    );
}

export default CoverImage;
