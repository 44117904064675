import ApiInstance from "../configs/apiInstance";

async function UpdateCafeProfileImage(id,profileImage) {
    try {
        const response = await ApiInstance.cafeService.put(`${id}`,{
            profileImage,
        });
        const data = response.data;
        console.log(data)
        return data;
    } catch (error) {
        console.log("Error occurred when user update", error);
        throw error;
    }
}

export default UpdateCafeProfileImage;