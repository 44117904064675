import axios from 'axios'
import configs from './endpoints.json'

const cafeService = axios.create({
    baseURL: `${configs.cafeService}`,
    headers: {
        'Content-Type': 'application/json',
    }
})

const userAwsImageService = axios.create({
    baseURL: `${configs.userAwsImageService}`,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

const userService = axios.create({
    baseURL: `${configs.userService}`,
    headers: {
        'Content-Type': 'application/json',
    }
})


const apiInstance = {
    cafeService,
    userAwsImageService,
    userService
}

export default apiInstance;